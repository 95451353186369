import {createTheme} from '@mui/material/styles'

import breakpoints from '../dashboard/styles/abstracts/breakpoints.module.scss'
import colors from '../dashboard/styles/abstracts/colors.module.scss'

const theme = createTheme({
  breakpoints: {
    values: {
      mobileSm: parseInt(breakpoints.mobileSm.replace('px', ''), 10),
      mobileMd: parseInt(breakpoints.mobileMd.replace('px', ''), 10),
      mobileLg: parseInt(breakpoints.mobileLg.replace('px', ''), 10),
      tabletSm: parseInt(breakpoints.tabletSm.replace('px', ''), 10),
      tabletMd: parseInt(breakpoints.tabletMd.replace('px', ''), 10),
      tabletLg: parseInt(breakpoints.tabletLg.replace('px', ''), 10),
      desktopSm: parseInt(breakpoints.desktopSm.replace('px', ''), 10),
      desktopMd: parseInt(breakpoints.desktopMd.replace('px', ''), 10),
      desktopLg: parseInt(breakpoints.desktopLg.replace('px', ''), 10),
      desktopXl: parseInt(breakpoints.desktopXl.replace('px', ''), 10)
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          boxShadow: 'none',
          padding: '0',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          padding: '0',
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'auto'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: colors.bodyText,
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontSize: '0.8125rem',
          lineHeight: '166%',
          margin: '3px 0 0'
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            padding: '12px',
            textAlign: 'left'
          }
        },
        root: {
          '& input[placeholder]': {
            color: colors.bodyText,
            fontSize: '15px',
            fontWeight: '300',
            lineHeight: '113.333%'
          },
          '&.MuiOutlinedInput-root': {
            background: colors.boxBackground,
            borderRadius: '8px',
            color: colors.bodyText,
            fontSize: '15px',
            fontWeight: '300'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: colors.bodyText,
          fontSize: '15px',
          fontWeight: '300',
          lineHeight: '113.333%',
          opacity: '0.5',
          '&[data-shrink="true"]': {
            display: 'none'
          },
          '&.Mui-focused': {
            color: 'unset'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: `1px solid ${colors.textboxBorder}`
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        textTransform: 'none'
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.tooltipBackground,
          borderRadius: '8px',
          fontSize: '0.625rem',
          fontWeight: '400',
          lineHeight: '140%'
        },
        arrow: {
          color: colors.tooltipBackground
        }
      }
    }
  },
  palette: {
    primary: {
      boxBackground: colors.bridalHeath,
      main: '#14158B',
      light: colors.everplansPrimaryLight,
      contrastText: colors.buttonTextLight,
      disabled: colors.primaryButtonDisabledBackground,
      text: colors.headerText,
      bodyText: colors.bodyText
    },
    secondary: {
      main: '#FF7670',
      green: colors.everplansSecondaryGreen
    },
    cancel: {
      main: colors.cancelButtonBackground,
      light: colors.cancelButtonHoverBackground,
      contrastText: colors.buttonTextLight
    },
    error: {
      main: '#D10303'
    }
  },
  shape: {
    borderRadius: '24px'
  },
  typography: {
    fontFamily: 'proxima-nova, Helvetica, Arial, sans-serif'
  }
})

export default theme
