/** @module dateTools
 *
 * @desc
 * Helpers related to the conversion, formatting, and parsing of dates.
 */

import moment from 'moment'
import momentTimezone from 'moment-timezone'
import {Map} from 'immutable'

export const monthDayYear = (date, format) => (
  date ? moment.utc(date).format(format || 'LL') : ''
)

/**
 * Groups and sorts a List of immutable objects that have a date attribute according to what is given or to the default of
 * created_at.
 *
 * @param {List} data with date object
 * @param {string} groupBy what the list should be grouped and sorted by
 *
 * @returns {List} grouped and sorted List() from the most recent date
 */
export const groupByDate = ({resourceList, groupBy = 'created_at'}) => (
  resourceList
    .sortBy(resource => resource.get(groupBy))
    .groupBy(resource => monthDayYear(resource.get(groupBy)))
    .reverse()
)

/**
 * @param {List} resourceList with date attributes
 * @param {string} sortBy which date attribute the list should be sorted-by
 *
 * @returns {List} of resources sorted by the most recent date using the `sortBy` attribute or `created-at` as a default
 */
export const sortByDate = ({resourceList, sortBy = 'created-at'}) => (
  resourceList
    .sortBy(resource => resource.get(sortBy))
    .reverse()
)


/**
 * Converts a date as a string (in the standard format returned by our Rails app) to a time
 * with timezone and the long-form date.
 *
 * @example
 * // returns '5:16 PM EDT, March 14, 2018'
 * timeWithZoneMonthDayYear('2018-03-14T18:16:59.355Z')
 *
 * @param {string} date - The date to be formatted.
 * @param {number} [additionalHours=0] - The number of hours after the given `date` to add before formatting.
 * @param {string} [timezone='America/New_York'] - The string representing the timezone for which to format the date.
 * @returns {string} the given `date` formatted in the given `timezone` after the `additionalHours` have
 *   been added to the `date`.
 */
export const timeWithZoneMonthDayYear = ({date, additionalHours, timezone}) => momentTimezone(date)
  .add(additionalHours ? additionalHours : 0, 'hours')
  .tz(timezone ? timezone : 'America/New_York')
  .format('h:mm A zz, MMMM D, YYYY')

export const updatedAtDate = (data, format) => monthDayYear(data.maxBy(item => new Date(item.get('updated-at'))).get('updated-at'), format)

export const createdAtDate = (data, format) => monthDayYear(data.maxBy(item => new Date(item.get('created-at'))).get('created-at'), format)

/**
 * Returns a formatted date object used to pre-populate the Date fields in a form with any existing data. Uses the mask to
 * figure out which fields the user inputed, since dates are saved with default values if missing `day`, `month`, or `year`.
 *
 * @param {string} date
 * @param {number} mask for the entered date in the Date fields
 *
 * @returns {Map} of `day`, `month`, and `year` used to pre-populate the Date field in a form with any existing data
 */
export const dateFieldsFromMask = dateData => {
  const utcDate = moment.utc(dateData.get('date'))
  const day = utcDate.format('DD')
  const month = utcDate.format('MM')
  const year = utcDate.format('YYYY')

  switch (dateData.get('mask')) {
    case 1:
      return Map({day})
    case 2:
      return Map({month})
    case 3:
      return Map({day, month})
    case 4:
      return Map({year})
    case 5:
      return Map({day, year})
    case 6:
      return Map({month, year})
    case 7:
      return Map({day, month, year})
    default:
      return Map()
  }
}

/**
 * Formatted date object used to populate the Date fields in a form with any existing data. If the dateData consists of a date and
 * mask then it uses the mask to figure out which fields the user inputed, since dates are saved with default values
 * if missing `day`, `month`, or `year`.
 *
 * @param {Map} dateData - that includes a date and mask or an object with a combination of `month`, `day`, `year`
 *
 * @returns {Map} of `day`, `month`, and `year` used to pre-populate the Date field in a form with any existing data
 */
export const dateFieldValuesForForm = dateData => {
  if (dateData && dateData.get('date'))
    return dateFieldsFromMask(dateData)
  else
    return Map(dateData)
}

/**
 * Takes a response that is a DATE type and returns the display value, depending on the value of the mask value.
 *
 * @param {Map} response - response that is type DATE, which includes a date and mask
 * @returns {string} used to display a response with type `date`
 */
export const dateResponseValue = responseValue => {
  const date = moment.utc(responseValue.get('date'))

  switch (responseValue.get('mask')) {
    case 1:
      return date.format('Do')
    case 2:
      return date.format('MMMM')
    case 3:
      return date.format('MMMM Do')
    case 4:
      return date.format('YYYY')
    case 5:
      return date.format('Do, YYYY')
    case 6:
      return date.format('MMMM YYYY')
    case 7:
      return date.format('MMMM DD, YYYY')
    default:
      return ''
  }
}

/**
 * @param {Map} dateFieldValues - that can include a day, month and year
 *
 * @returns {number} mask for the entered date in the Date fields
 */
export const dateMaskFromForm = dateFieldValues => {
  const reducer = (mask, dateValue, dateField) => {
    if (dateField === 'day')
      return mask + 1
    else if (dateField === 'month')
      return mask + 2
    else if (dateField === 'year')
      return mask + 4
    return mask
  }

  return dateFieldValues.reduce(reducer, 0)
}


export const VALID_DATE_FORMATS = ['YYYY-MM-DD', 'YYYY-M-D', 'YYYY-MM-D', 'YYYY-M-DD']

/**
 * @param {Map} dateFieldValues - that can include a day, month and year
 *
 * @returns {string} date from the Date fields with default values if any fields are missing
*/
export const dateFromForm = dateFieldValues => {
  const date = `${dateFieldValues.get('year', '2000')}-${dateFieldValues.get('month', '01')}-${dateFieldValues.get('day', '01')}`

  return moment(
    date,
    VALID_DATE_FORMATS,
    true // Enables strict mode to restrict formats to `VALID_DATE_FORMATS`
  ).format('YYYY-MM-DD')
}

/**
 * Returns the mask and date from the values of the Date fields
 *
 * @param {Map} dateFieldValues from the Date fields and can include day, month and year
 * @returns {Map} of the mask and date needed to save a response that is a DATE type
 */
export const dateWithMaskFromForm = dateFieldValues => {
  if (dateFieldValues.get('mask'))
    return dateFieldValues

  const values = dateFieldValues.filter(value => value)

  if (!values.isEmpty())
    return Map({mask: dateMaskFromForm(values), date: dateFromForm(values)})
}

export const dateToISOString = date => (new Date(date).toISOString())

export const formatDate = (date, format = 'L') => monthDayYear(date, format) || 'N/A'

export const birthdateValidatorFormat = data => {
  const value = data || {}
  const {day, month, year} = value

  if (day && month && year) {
    const newDate = new Date(`${month}/${day}/${year}`)

    if (
      newDate.getDate() === parseInt(day, 10) &&
      (newDate.getMonth() + 1) === parseInt(month, 10) &&
      newDate.getFullYear() === parseInt(year, 10)
    )
      return newDate
    else
      return 'Invalid Date'
  } else {
    return null
  }
}

export const dateToFromNowDaily = date => {
  // get from-now for this date
  const fromNow = moment.utc(date).local().fromNow()
  // ensure the date is displayed with today and yesterday
  return moment.utc(date).local().calendar(null, {
    // when the date is closer, specify custom values
    lastDay: '[Yesterday]',
    // when the date is further away, use from-now functionality
    sameElse: () => `[${fromNow}]`
  })
}
